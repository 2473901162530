<template>
  <div id="404">
    <section class="hero is-black is-fullheight">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">
            404
          </h1>
          <h2 class="subtitle">
            {{ $root.t(`We weren't able to find what you were looking for.`) }}

          </h2>
          <router-link to="/" class="button">
            home
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "404",

  data() {
    return {}
  },

  created() {
    // eslint-disable-next-line no-unused-vars
    let self = this;


  },

  methods: {

  },

}

</script>

<style scoped lang="scss">

</style>
